// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';

import Contact from './pages/Contact';
import WashingMachine from './pages/WashingMachine';
import Fridge from './pages/Fridge';
import NavBar from './components/NavBar';
import Footer from './components/Footer'; 
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsConditions from './pages/TermsAndConditions'
import './App.css';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  return (
    <>
    <Router>
      <div className="App">
        <NavBar />
        <ScrollToTopButton/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services/washing-machine" element={<WashingMachine />} />
          <Route path="/services/fridge" element={<Fridge />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
          <Route path="/TermsConditions" element={<TermsConditions />} />
        </Routes>
        <Footer /> 
      </div>
    </Router>


<div class="fixed-phone-call">
    <a href="tel:+918870202780" class="phone-button">Call Us:+918870202780</a>
</div>

</>
  );
}

export default App;
