// WashingMachine.js
import React from 'react';
import WASHHOME from '../images/WASHHOME.jpg'; // Replace with the actual path to your image
import WASHERMAN from '../images/WASHERMAN.jpg'; // Replace with the actual path to your image
import WASHERMAN2 from '../images/WASHERMAN2.jpeg'; // Replace with the actual path to your image
import './WashingMachine.css';
function WashingMachine() {
  return (
   <div className="home">
        <section id="hero" style={{ backgroundImage: `url(${WASHHOME})` }}>
          <div className="hero-content">
            <h1>Washing Machine Repair Service</h1>
            <p>"Hari Appliance Care – Trusted Care for Every Repair!"</p>
            <a href="tel:+918870202780" className="hero-btn">Book Service?</a>
          </div>
        </section>
  
        <section id="about">
  <div className="container">
    <h2>About Us - Washing Machine Service</h2>
    <p>We specialize in providing high-quality, reliable, and efficient washing machine repair services. With over 12 years of experience, our team of experts is dedicated to getting your washing machine back in perfect working condition. Whether it's a minor issue or a major repair, we handle all types of washing machines from various brands. Our technicians are well-trained, certified, and equipped with the right tools to deliver top-notch service right at your doorstep.</p>
    
    <div className="row">
      <div className="card">
        <div className="card-icon">
          <i className="fas fa-tools"></i>
        </div>
        <div className="card-content">
          <h3>Washing Machine Repair</h3>
          <p>We provide expert repair services for all washing machine models, from common issues like drainage problems, motor failures, and drum malfunctions to more complex electrical and mechanical repairs. Our service is fast, reliable, and affordable, ensuring that your washing machine is up and running as quickly as possible.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-icon">
          <i className="fas fa-cogs"></i>
        </div>
        <div className="card-content">
          <h3>Maintenance & Troubleshooting</h3>
          <p>Regular maintenance is essential to keep your washing machine in optimal condition. We offer thorough inspections, cleaning, and preventive maintenance services to avoid breakdowns and ensure your washing machine runs smoothly. Let us help you save money on expensive repairs by catching problems early.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-icon">
          <i className="fas fa-recycle"></i>
        </div>
        <div className="card-content">
          <h3>Expertise Across All Brands</h3>
          <p>We have extensive experience working with a wide range of washing machine models, ensuring your washing machine is repaired with the highest level of expertise and care, regardless of the brand.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-icon">
          <i className="fas fa-headset"></i>
        </div>
        <div className="card-content">
          <h3>24/7 Customer Support</h3>
          <p>Our customer support team is available round the clock to assist you with your washing machine service needs. Whether you need a quick diagnosis, emergency repair, or maintenance booking, we're here to help at any time of the day or night.</p>
        </div>
      </div>
    </div>

    <h3>Why Choose Us for Your Washing Machine?</h3>
    <ul>
      <li><strong>Experienced Technicians:</strong> Our certified and well-trained technicians have years of experience in washing machine repair and maintenance.</li>
      <li><strong>Affordable and Transparent Pricing:</strong> We offer competitive prices with no hidden charges, ensuring you get value for your money.</li>
      <li><strong>Fast Response Time:</strong> We understand how inconvenient it is when your washing machine breaks down, which is why we prioritize quick and efficient service.</li>
      <li><strong>Quality Assurance:</strong> We guarantee high-quality repairs with long-lasting results, backed by our warranty on parts and service.</li>
    </ul>

    <h3>Our Service Areas</h3>
    <p>We offer washing machine repair services to multiple locations. No matter where you are, we're just a phone call away. We proudly serve homes and businesses across the region, bringing professional service right to your doorstep.</p>

    <p>If you're looking for fast, reliable, and affordable washing machine repair, look no further. Contact us today to schedule an appointment and get your washing machine back to its best condition!</p>
  </div>
</section>


  
  
  
        <section className="description-section">
  
  {/* First Row: Washing Machine Service */}
  <div className="description-row">
    <div className="description-image">
      <img src={WASHERMAN} alt="Washing Machine" />
    </div>
    <div className="description-content">
      <h3>Washing Machine Service</h3>
      <p>
        <strong>Hari Appliance Care</strong> offers top-quality washing machine repair and service for all brands. Our expert technicians provide reliable solutions to keep your washing machine running smoothly.
      </p>
    </div>
  </div>
  
  {/* Second Row: Fridge Service */}
  <div className="description-row">
    <div className="description-image">
      <img src={WASHERMAN2} alt="Fridge" />
    </div>
    <div className="description-content">
      <h3>Washer Service</h3>
      <p>
      Hari Appliance Care is your trusted partner for washing machine repair and maintenance. We handle all washing machine brands with precision and care to ensure optimal performance.
      </p>
    </div>
  </div>
  
  </section>
  
  
        <section id="we-are-the-best">
    <div className="container">
      <h2>We Are the Best</h2>
      <div className="row">
        <div className="card">
          <div className="card-icon">
            <i className="fas fa-wrench"></i>
          </div>
          <div className="card-content">
            <h3>Expert Washing Machine Service</h3>
            <p>Specialized in solving all washing machine issues efficiently.</p>
          </div>
        </div>
  
        <div className="card">
          <div className="card-icon">
            <i className="fas fa-thermometer-half"></i>
          </div>
          <div className="card-content">
            <h3>Reliable Fridge Service</h3>
            <p>Ensuring your fridge functions like new with our premium repair solutions.</p>
          </div>
        </div>
  
        <div className="card">
          <div className="card-icon">
            <i className="fas fa-star"></i>
          </div>
          <div className="card-content">
            <h3>Trusted by Thousands</h3>
            <p>Our customers trust us for our quality service and timely solutions.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  
  
  
  
  
  
  
  <section id="how-we-get-customers">
    <div className="container">
      <h2>How We Get Customers</h2>
      <div className="row">
        <div className="card">
          <div className="card-icon">
            <i className="fas fa-thumbs-up"></i>
          </div>
          <div className="card-content">
            <h3>Trustable Service Center</h3>
            <p>Our reputation is built on trust and high-quality service.</p>
          </div>
        </div>
  
        <div className="card">
          <div className="card-icon">
            <i className="fas fa-globe"></i>
          </div>
          <div className="card-content">
            <h3>Website & Google Ads</h3>
            <p>We connect with customers through our website and Google Ads promotion.</p>
          </div>
        </div>
  
        <div className="card">
    <div className="card-icon">
      ₹
    </div>
    <div className="card-content">
      <h3>Low Service Charge</h3>
      <p>Our service starts at just ₹499, with spare parts charged separately.</p>
    </div>
  </div>
  
  
        <div className="card">
          <div className="card-icon">
            <i className="fas fa-user-check"></i>
          </div>
          <div className="card-content">
            <h3>Transparent Work</h3>
            <p>We keep customers informed about every step of the repair process.</p>
          </div>
        </div>
  
        <div className="card">
          <div className="card-icon">
            <i className="fas fa-shield-alt"></i>
          </div>
          <div className="card-content">
            <h3>Warranty & Guarantee</h3>
            <p>We offer a 3–6 month warranty on services and spare parts.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  <div className="card">
    <div className="card-icon">
      <i className="fas fa-clock"></i>
    </div>
    <div className="card-content">
      <h3>Opening Hours</h3>
      <ul className="hours-list">
        <li><strong>Monday:</strong> 8:00 AM - 8:00 PM</li>
        <li><strong>Tuesday:</strong> 8:00 AM - 8:00 PM</li>
        <li><strong>Wednesday:</strong> 8:00 AM - 8:00 PM</li>
        <li><strong>Thursday:</strong> 8:00 AM - 8:00 PM</li>
        <li><strong>Friday:</strong> 8:00 AM - 8:00 PM</li>
        <li><strong>Saturday:</strong> 8:00 AM - 8:00 PM</li>
        <li><strong>Sunday:</strong> Holiday</li>
      </ul>
    </div>
  </div>
  
  <div className="card disclaimer-card">
    <div className="card-icon">
      <i className="fas fa-exclamation-circle"></i>
    </div>
    <div className="card-content">
      <h3>Disclaimer</h3>
      <p>
           <strong>Hari Appliance Care</strong> is not an authorized repair service center. We are a multibrand repair service center and can provide repair service for any brand. However, please note that we are not authorized by any brand for their services.
         </p>
         <p>
           Our services are independent and we provide repairs, maintenance, and support for various home appliances across brands.
         </p>
    </div>
  </div>
  
  
      </div>
  );
}

export default WashingMachine;
