// src/pages/Contact.js
import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <section id="contact">
      <div className="container">
        <h2>Contact Us - Hari Appliance Care</h2>
        <p>Get in touch with us for all your refrigerator repair and maintenance needs. Our team is here to assist you with any questions or service requests you may have. We're committed to providing the best service possible!</p>
        
        <div className="row">
          {/* Contact Information */}
          <div className="contact-info col-md-6">
            <h3>Our Contact Information</h3>
            <ul>
              <li><strong>Phone Number:</strong>+918870202780</li>
              <li><strong>Email:</strong> <a href="mailto:hariappliancecare@gmail.com">hariappliancecare@gmail.com</a></li>
              <li><strong>Website:</strong> <a href="https://www.hariappliancecare.com" target="_blank" rel="noopener noreferrer">www.hariappliancecare.com</a></li>
              <li><strong>Business Hours:</strong> Monday - Saturday, 8:00 AM - 8:00 PM</li>
              <li><strong>Location:</strong> 284, 2nd Cross Street, Eswari Nagar, Nagalkeni, Chromepet, Kancheepuram,Tami Nadu-600044</li>
            </ul>
          </div>

          {/* Google Map */}
          <div className="contact-map col-md-6">
            <h3>Find Us Here</h3>
            {/* Replace YOUR_GOOGLE_MAP_EMBED_URL with actual Google Map Embed URL */}
            <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3826.338888294548!2d80.270718!3d13.082680!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267d7d8a86e67%3A0x8e9889bf07bb757b!2sChennai%2C%20Tamil%20Nadu%2C%20India!5e0!3m2!1sen!2sin!4v1700000000000!5m2!1sen!2sin"
  width="100%"
  height="400"
  frameBorder="0"
  style={{ border: 0 }}
  allowFullScreen
  aria-hidden="false"
  tabIndex="0"
></iframe>



          </div>
        </div>

        <div className="row">
          {/* Social Media Links */}
          {/* <div className="social-links col-md-12">
            <h3>Follow Us</h3>
            <p>Stay connected with us on social media for updates and helpful tips on refrigerator maintenance and more:</p>
            <ul>
              <li><a href="https://facebook.com/hariappliancecare" target="_blank" rel="noopener noreferrer">Facebook</a></li>
              <li><a href="https://instagram.com/hariappliancecare" target="_blank" rel="noopener noreferrer">Instagram</a></li>
              <li><a href="https://twitter.com/hariappliancecare" target="_blank" rel="noopener noreferrer">Twitter</a></li>
              <li><a href="https://linkedin.com/company/hariappliancecare" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            </ul>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Contact;
