import React from 'react';

function TermsAndConditions() {
  return (
    <>
    
    
    
    
    <section
  id="TC"
  style={{
    padding: '20px',
    backgroundColor: '#f9f9f9',
    color: '#333',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  }}
>
  <div
    style={{
      maxWidth: '800px',
      margin: 'auto',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      background: 'white',
      padding: '20px',
    }}
  >
    <h1
      style={{
        textAlign: 'center',
        fontSize: '28px',
        color: '#555',
        marginBottom: '10px',
      }}
    >
      Terms and Conditions
    </h1>
    <p
      style={{
        textAlign: 'center',
        fontSize: '16px',
        color: '#777',
        marginBottom: '20px',
      }}
    >
      Effective Date: January 3, 2025
    </p>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Acceptance of Terms</h2>
    <p>
      By using the services provided by Hari Appliance Care, you agree to comply with and
      be bound by these Terms and Conditions. Please read them carefully before
      availing of our services.
    </p>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Services</h2>
    <p>
      Hari Appliance Care offers appliance repair and maintenance services for
      washing machines, refrigerators, and other home appliances. All services are
      provided as per customer requests.
    </p>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Customer Responsibilities</h2>
    <ul
      style={{
        listStyleType: 'square',
        paddingLeft: '20px',
        marginBottom: '15px',
      }}
    >
      <li>Provide accurate information when scheduling a service.</li>
      <li>Ensure access to the appliance and a safe working environment for our technicians.</li>
      <li>Inform us promptly of any changes to scheduled appointments.</li>
    </ul>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Payment Terms</h2>
    <ul
      style={{
        listStyleType: 'square',
        paddingLeft: '20px',
        marginBottom: '15px',
      }}
    >
      <li>Payment is due upon completion of the service unless agreed otherwise.</li>
      <li>We accept cash, card, or online payment methods.</li>
      <li>Any disputes regarding payment must be raised within 7 days of the service date.</li>
    </ul>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Service Warranty</h2>
    <p>
      We provide a warranty on certain repairs and parts, as discussed and
      agreed upon during the service. Warranty claims must be made within the
      specified period.
    </p>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Limitation of Liability</h2>
    <p>
      Hari Appliance Care is not liable for:
    </p>
    <ul
      style={{
        listStyleType: 'square',
        paddingLeft: '20px',
        marginBottom: '15px',
      }}
    >
      <li>Damage caused by misuse or improper handling of the appliance.</li>
      <li>Delays in service due to unforeseen circumstances.</li>
      <li>Pre-existing issues with the appliance that were not disclosed.</li>
    </ul>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Changes to Terms</h2>
    <p>
      We reserve the right to update or modify these Terms and Conditions at any
      time. Customers will be notified of significant changes.
    </p>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Contact Us</h2>
    <p>
      If you have questions about these Terms and Conditions, please contact us:
    </p>
    <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
      <li>
        <strong>Email:</strong>{' '}
        <a
          href="mailto:hariappliancecare@gmail.com"
          style={{ color: '#0066cc', textDecoration: 'none' }}
        >
          hariappliancecare@gmail.com
        </a>
      </li>
      <li>
        <strong>Phone:</strong>{' '}
        <a
          href="tel:+919551399803"
          style={{ color: '#0066cc', textDecoration: 'none' }}
        >
          +91 95513 99803
        </a>
      </li>
    </ul>

    <p
      style={{
        textAlign: 'center',
        marginTop: '20px',
        fontSize: '14px',
        color: '#999',
      }}
    >
      © 2025 Hari Appliance Care. All rights reserved.
    </p>
  </div>
</section>

    
    
    
    
    
    
    </>
  );
}

export default TermsAndConditions;
