import React from 'react'


export default function PrivacyPolicy() {
  return (
    <>
   
    
    
    <section
  id="PP"
  style={{
    padding: '20px',
    backgroundColor: '#f9f9f9',
    color: '#333',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  }}
>
  <div
    style={{
      maxWidth: '800px',
      margin: 'auto',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      background: 'white',
      padding: '20px',
    }}
  >
    <h1
      style={{
        textAlign: 'left',
        fontSize: '28px',
        color: '#555',
        marginBottom: '10px',
      }}
    >
      Privacy Policy
    </h1>
    <p
      style={{
        textAlign: 'left',
        fontSize: '16px',
        color: '#777',
        marginBottom: '20px',
      }}
    >
      Effective Date: January 3, 2025
    </p>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Introduction</h2>
    <p>
      At Hari Appliance Care, we prioritize your privacy and are committed to
      protecting the personal information you provide while using our services.
      This Privacy Policy outlines how we collect, use, and safeguard your data
      in compliance with applicable laws.
    </p>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Information We Collect</h2>
    <ul
      style={{
        listStyleType: 'square',
        paddingLeft: '20px',
        marginBottom: '15px',
      }}
    >
      <li>Name, Address, and Contact Information (Phone Number, Email).</li>
      <li>Details about the appliances for which you request services.</li>
      <li>Feedback and reviews provided voluntarily.</li>
    </ul>

    <h2 style={{ color: '#333', fontSize: '20px' }}>How We Use Your Information</h2>
    <p>Your personal data is used solely to provide and improve our services, including:</p>
    <ul
      style={{
        listStyleType: 'square',
        paddingLeft: '20px',
        marginBottom: '15px',
      }}
    >
      <li>Scheduling repair services for washing machines, fridges, and other appliances.</li>
      <li>Sending service updates and follow-up reminders.</li>
      <li>Improving service quality based on your feedback.</li>
    </ul>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Data Security</h2>
    <p>
      We use secure systems to store your data and ensure it is accessed only by
      authorized personnel. Your data will not be shared or sold to third parties.
    </p>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Your Rights</h2>
    <p>You have the right to:</p>
    <ul
      style={{
        listStyleType: 'square',
        paddingLeft: '20px',
        marginBottom: '15px',
      }}
    >
      <li>Access and review the information we have collected about you.</li>
      <li>Request corrections to inaccurate or incomplete information.</li>
      <li>Request the deletion of your personal information.</li>
    </ul>

    <h2 style={{ color: '#333', fontSize: '20px' }}>Contact Us</h2>
    <p>
      If you have questions or concerns about this Privacy Policy, please contact
      us:
    </p>
    <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
      <li>
        <strong>Email:</strong>{' '}
        <a
          href="mailto:hariappliancecare@gmail.com"
          style={{ color: '#0066cc', textDecoration: 'none' }}
        >
          hariappliancecare@gmail.com
        </a>
      </li>
      <li>
        <strong>Phone:</strong>{' '}
        <a
          href="tel:+919551399803"
          style={{ color: '#0066cc', textDecoration: 'none' }}
        >
          +91 95513 99803
        </a>
      </li>
    </ul>

    <p
      style={{
        textAlign: 'left',
        marginTop: '20px',
        fontSize: '14px',
        color: '#999',
      }}
    >
      © 2025 Hari Appliance Care. All rights reserved.
    </p>
  </div>
</section>


    
    
    
    </>
  )
}
