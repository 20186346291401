import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

function NavBar() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDropdownToggle = (dropdown) => {
    if (activeDropdown === dropdown) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdown);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false); // Close the menu when any link is clicked
    setActiveDropdown(null); // Close any open dropdown
  };

  return (
    <nav className="navbar">
      <div className="logo">Hari Appliance Repair Care</div>
      <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
        <li><Link to="/about" onClick={handleLinkClick}>About</Link></li>
        <li
          className="dropdown"
          onClick={() => handleDropdownToggle('services')}
        >
          <span>Services</span>
          <ul className={`dropdown-menu ${activeDropdown === 'services' ? 'open' : ''}`}>
            <li><Link to="/services/washing-machine" onClick={handleLinkClick}>Washing Machine</Link></li>
            <li><Link to="/services/fridge" onClick={handleLinkClick}>Fridge</Link></li>
          </ul>
        </li>
        <li><Link to="/contact" onClick={handleLinkClick}>Contact</Link></li>
      </ul>
    </nav>
  );
}

export default NavBar;
